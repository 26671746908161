<template>
  <section class="no-select">
    <template v-if="isReady && !isBillingInfoRequired">
      <UserPlanPricing
        :payload="payload"
        :busy="busy"
        @requestPlanChange="$emit('requestPlanChange', $event)"
        @upgrade="onUpgrade"
      />
    </template>
    <template v-if="isReady && isBillingInfoRequired">
      <UserPlanBillingInformation
        :payload="payload"
        :component_busy="busy"
        @hide-billing-information="onHideBillingInformation"
        @finish-contracting="onFinishContracting"
      />
    </template>
  </section>
</template>

<script>
import Pricing from "@/assets/dashboard/pricing.json";
import ContractService from "@/services/contract.js";
import MixinAlert from "@/project/mixin-alert.js";
import UserPlanPricing from "@/components/user-plan/user-plan-pricing.vue";
import UserPlanBillingInformation from "@/components/user-plan/user-plan-billing-information.vue";

import { contractStateConst } from "@/assets/constants.js";

export default {
  name: "UserPlanUpgradeB",
  mixins: [MixinAlert],
  components: {
    UserPlanPricing,
    UserPlanBillingInformation
  },
  props: {},
  data() {
    return {
      payload: {},
      busy: false,
      isBillingInfoRequired: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    isTest() {
      return this?.contract?.contract_state?.id == contractStateConst.TEST;
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    plan() {
      return ((this?.plans || []).find(
        (item) => (
          item.name.toLowerCase() == this?.contract?.contract_plan?.name.toLowerCase()
        )
      ) || {});
    },
    contractPlanIndex() {
      return (this.plans || []).findIndex(
        ({ id }) => id == (this?.contract?.contract_plan?.id || -1)
      );
    },
    selectedPlanIndex() {
      return (this.plans || []).findIndex(
        ({ id }) => id == (this?.payload?.plan_identity_number || -1)
      );
    },
    isPlanChanged() {
      let originalPlan = [
        this.contractPlanIndex,  
        this?.contract?.is_yearly,
        this?.contract?.maximum_data
      ];
      let newPlan = [
        this.selectedPlanIndex,
        this?.payload?.is_yearly,
        this?.payload?.plan_data_range,
      ];
      return originalPlan != newPlan;
    },
    resources() {
      return (
        (this.contract &&
          this.contract.billing &&
          this.contract.billing.resources) ||
        null
      );
    },
    isReady() {
      return this.resources != null;
    },
    usesPaymentGateway() {
      if (this.isTest) return false;
      return this.$root?.config?.references?.payment_gateway;
    },
    plans() {
      return JSON.parse(
        JSON.stringify(
          this.contract.is_white_label ? Pricing.whitelabel : Pricing.regular
        )
      );
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    onHideBillingInformation() {
      this.isBillingInfoRequired = false;
    },
    upgradeText() {
      let planName = this.plans[this.selectedPlanIndex].title;
      return `${planName} - ${this.$t("up_to")} ${this.payload.plan_data_range}
        ${this.$tc("data", 2)}`;
    },
    onUpgrade(payload) {
      if (!payload) return;
      if (!this.isPlanChanged);

      let self = this;
      this.payload = payload;

      if (!this.isDemo) {
        this.payload.id = (self.contract && self.contract.id) || "";
        this.payload.etag = (self.contract && self.contract.etag) || "";
      }

      // Se não for teste e utilizar integração com método de pagamento,
      // solicita dados de cobrança
      if (self.usesPaymentGateway) {
        self.isBillingInfoRequired = true;
      } else {
        self.onFinishContracting(this.payload);
      }
    },
    onFinishContracting(payload) {
      let self = this;

      self.busy = true;

      // Mensagem de confirmação antes de iniciar as gravações
      self.$swal({
          title: self.upgradeText(),
          text: self.$t("do_you_really_want_to_upgrade_your_current_plan"),
          icon: self.plans[self.selectedPlanIndex].image,
          imageSize: "32x32",
          buttons: [self.$t("cancel"), self.$t("yes_im_sure")]
        })
        .then(function(isConfirm) {
          if (!isConfirm) {
            self.busy = false;
            return;
          }

          // Se não for teste e utilizar integração com método de pagamento,
          // faz o processo completo de cobrança
          if (self.usesPaymentGateway) {
            // Finalizar atualização do contrato
            // Salvar alterações do Perfil do usuário
            let profile_payload = {
              user_profile: {
                company_name: payload.company_name,
                cpf_cnpj: payload.cpf_cnpj,
                phone: payload.phone,
                zipcode: payload.zipcode,
                number: payload.number,
                complement: payload.complement,
                email_billing: payload.email_billing
              }
            };
            
            self.$store.dispatch("user/updateProfile", profile_payload)
              .then(() => {
                // Finalizar atualização do contrato
                self.finishContracting(payload).then(()=>(self.busy = false));
              })
              .catch((err) => {
                // Erro ao salvar o Perfil
                self.$swal({
                  title: self.$t("item_could_not_be_saved"),
                  text: err.message ? err.message : self.$t("your_plan_could_not_be_updated"),
                  icon: "error"
                });
                self.busy = false;
              });
          } else {
            // Não é teste ou não tem integração com método de pagamento,
            // apenas atualiza o contrato
            self.finishContracting(payload).then(()=>(self.busy = false));
          }
        });
    },
    finishContracting(payload) {
      let self = this;

      // Fazer o checkout transparente
      return new Promise((resolve, reject) => {
        self.service
          .updateContract(payload)
          .then((result) => {
            resolve(result);
            // Sucesso ao realizar checkout
            self.$swal({
                title: self.$t("completed"),
                text: self.$t("your_plan_was_updated"),
                icon: "success"
              })
              .then(() => {
                document.location.reload();
              });
          })
          .catch((err) => {
            reject(err);
            // Erro ao realizar o checkout
            self.$swal({
              title: self.$t("item_could_not_be_saved"),
              text: err.message ? err.message : self.$t("your_plan_could_not_be_updated"),
              icon: "error"
            });
          });
      });
    }
  },
  created() {
    this.service = new ContractService();
  },
  mounted() {
    this.payload = {
      plan_identity_number: this?.plan?.id,
      is_yearly: this?.contract?.is_yearly,
      plan_data_range: this?.contract?.maximum_data
    }
  }
}
</script>