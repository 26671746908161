<template>
  <form
    class="billing-information-form"
    action=""
    autocomplete="off"
    v-on:submit.prevent="onUpdateProfile"
  >
    <div class="row">
      <div class="col-md-4">
        <div class="selected-plan-info">
          <div class="row">
            <div class="col-xs-6">
              <Icon name="fa fa-arrow-right" />&nbsp; {{ $t("plan") }}:
            </div>
            <div class="col-xs-6 text-green">
              <label
                >{{
                  selectedPlanName +
                    " + " +
                    selectedDataRange +
                    " " +
                    $tc("data", 2)
                }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <Icon name="fa fa-refresh" />&nbsp; {{ $t("periodicity") }}:
            </div>
            <div class="col-xs-6 text-green">
              <label>{{ $t(selectedCommitment) }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <Icon name="fa fa-usd" />&nbsp; {{ $t("value") }}:
            </div>
            <div class="col-xs-6 text-green">
              <label>{{ priceInfo }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="alert alert-info">
          <h4><i class="icon fa fa-info"></i>{{ $t("about_the_billing") }}</h4>
          {{ $t("billing_details_information") }}
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-sm-6">
        <h4>{{ $t("complete_billing_information") }}</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group" :class="{ 'has-error': !isValidCompanyName }">
          <label for="company_name">
            <Icon :name="invalidIconClassName" v-if="!isValidCompanyName" />
            {{ $t("company_name") }}
          </label>
          <input
            type="text"
            class="form-control"
            maxlength="200"
            v-model="company_name"
            :disabled="busy"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group" :class="{ 'has-error': !isValidCpfCnpj }">
          <label for="cpf_cnpj">
            <Icon :name="invalidIconClassName" v-if="!isValidCpfCnpj" />
            {{ $t("cpf_cnpj") }}
          </label>
          <InputMasked
            id="cpf_cnpj"
            type="cpf_cnpj"
            v-model="cpf_cnpj"
            :disabled="busy"
          />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group" :class="{ 'has-error': !isValidPhone }">
          <label for="phone">
            <Icon :name="invalidIconClassName" v-if="!isValidPhone" />
            {{ $t("area_plus_phone") }}
          </label>
          <InputMasked
            id="phone"
            type="phone"
            v-model="phone"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 col-md-2">
        <div class="form-group" :class="{ 'has-error': !isValidZipcode }">
          <label for="zipcode">
            <Icon :name="invalidIconClassName" v-if="!isValidZipcode" />
            {{ $t("zipcode") }}
          </label>
          <InputMasked
            id="zipcode"
            type="zipcode"
            v-model="zipcode"
            :disabled="busy"
          />
        </div>
      </div>
      <div class="col-sm-3 col-md-2">
        <div class="form-group" :class="{ 'has-error': !isValidNumber }">
          <label for="number">
            <Icon :name="invalidIconClassName" v-if="!isValidNumber" />
            {{ $t("number") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="number"
            maxlength="10"
            v-model="number"
            :disabled="busy"
          />
        </div>
      </div>
      <div class="col-sm-3 col-md-2">
        <div class="form-group" :class="{ 'has-error': !isValidComplement }">
          <label for="complement">
            <Icon :name="invalidIconClassName" v-if="!isValidComplement" />
            {{ $t("complement") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="complement"
            maxlength="20"
            v-model="complement"
            :disabled="busy"
          />
        </div>
      </div>
      <div class="col-sm-3 col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidEmailBilling }">
          <label for="email_billing">
            <Icon :name="invalidIconClassName" v-if="!isValidEmailBilling" />
            {{ $t("email_billing") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="email_billing"
            maxlength="100"
            v-model="email_billing"
            :disabled="busy"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-xs-12 col-md-2">
        <button
          class="btn btn-default btn-block"
          @click="$emit('hide-billing-information')"
          :disabled="busy"
        >
          <Icon name="fa fa-chevron-left" />&nbsp;
          {{ $t("back") }}
        </button>
      </div>
      <div class="hidden-xs hiden-sm">
        <div class="col-md-7"></div>
      </div>
      <div class="hidden-md hidden-lg hidden-xl">
        <div class="col-md-12">
          <hr />
        </div>
      </div>
      <div class="col-xs-12 col-md-3 text-center">
        <button
          class="btn btn-primary btn-block contract"
          @click.stop.prevent="onUpdateProfile"
          :disabled="!isProfileValid || busy"
        >
          <template v-if="busy">
            <Icon name="fa fa-refresh fa-spin" />&nbsp;
            {{ $t("please_wait_no_timeout") }}
          </template>
          <template v-if="!busy">
            <Icon name="fa fa-check" />&nbsp;
            {{ $t("finish_contracting") }}
          </template>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Icon from "@/components/icons/icon";
import MixinAlert from "@/project/mixin-alert.js";
import errorMessages from "@/i18n/errors.js";
import InputMasked from "@/components/input-masked.vue";
import Pricing from "@/assets/dashboard/pricing.json";
import { onlyNumbers } from "@/plugins/utils";

export default {
  name: "UserPlanBillingInformation",
  mixins: [MixinAlert],
  components: {
    InputMasked,
    Icon
  },
  props: {
    payload: {
      type: Object,
      required: true
    },
    component_busy: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      }
    }
  },
  i18n: {
    messages: errorMessages
  },
  data() {
    return {
      plans: [],
      quotaOptions: [],
      selectedPlanIndex: -1,
      selectedQuotaIndex: -1,
      selectedDataRange: 0,
      selectedCommitment: "",
      company_name: "",
      cpf_cnpj: "",
      phone: "",
      zipcode: "",
      number: "",
      complement: "",
      email_billing: "",
      invalidIconClassName: "fa fa-exclamation-triangle ",
      busy: false
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters["user/loggedUser"] || {};
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    selectedPlan() {
      if (!this.plans.length) return null;
      return this.plans[this.selectedPlanIndex];
    },
    selectedPlanId() {
      if (!this.selectedPlan) return null;
      return this.selectedPlan.id;
    },
    selectedPlanName() {
      if (!this.selectedPlan) return null;
      return this.selectedPlan.title;
    },
    priceMultiplier() {
      return this.selectedCommitment == "monthly" ? 1 : 10;
    },
    selectedPrice() {
      if (!this.plans.length) return -1;
      let result = this.selectedPlan.monthly_price[this.selectedQuotaIndex]
        .value;
      if (typeof result != "number") return -1;
      return result * this.priceMultiplier;
    },
    priceInfo() {
      if (!this.plans.length) return "";
      return this.$utils.formatMoney(this.selectedPrice + "");
    },
    isProfileValid() {
      return (
        !this.busy &&
        this.isValidCompanyName &&
        this.isValidCpfCnpj &&
        this.isValidPhone &&
        this.isValidZipcode &&
        this.isValidNumber &&
        this.isValidComplement &&
        this.isValidEmailBilling
      );
    },
    isValidCompanyName() {
      return this.company_name && this.company_name.trim() != "";
    },
    isValidCpfCnpj() {
      return this.$utils.validateCpfCnpj(this.cpf_cnpj);
    },
    isValidPhone() {
      return this.$utils.validatePhone(this.phone);
    },
    isValidZipcode() {
      return this.$utils.validateZipcode(this.zipcode);
    },
    isValidNumber() {
      return this.number && this.number.trim() != "";
    },
    isValidComplement() {
      return true;
    },
    isValidEmailBilling() {
      return this.$utils.validateEmail(this.email_billing);
    }
  },
  watch: {
    loggedUser: {
      handler(n) {
        if (n) {
          this.first_name = n.first_name;
          this.last_name = n.last_name;
        }
      },
      deep: true,
      immediate: true
    },
    component_busy(n) {
      this.busy = n;
    }
  },
  methods: {
    reloadQuotaOptions(iplan) {
      this.quotaOptions = [];
      this.plans[iplan].monthly_price.forEach((v) => {
        this.quotaOptions.push(v.quota);
      });
    },
    setQuotaIndexByDataRange(value) {
      let selectedQuotaIndex = this.quotaOptions.findIndex(
        (quota) => (0 || value) <= quota
      );
      if (selectedQuotaIndex >= 0) {
        this.selectedQuotaIndex = selectedQuotaIndex;
      } else {
        this.selectedQuotaIndex = 0;
      }
    },
    onUpdateProfile() {
      if (!this.isProfileValid) return;
      this.busy = true;
      this.payload.company_name = this.company_name;
      this.payload.cpf_cnpj = onlyNumbers(this.cpf_cnpj);
      this.payload.phone = onlyNumbers(this.phone);
      this.payload.zipcode = onlyNumbers(this.zipcode);
      this.payload.number = this.number;
      this.payload.complement = this.complement;
      this.payload.email_billing = this.email_billing;
      this.$emit("finish-contracting", this.payload);
    }
  },
  mounted() {
    this.plans = JSON.parse(
      JSON.stringify(
        this.contract.is_white_label ? Pricing.whitelabel : Pricing.regular
      )
    );

    this.busy = this.component_busy;

    this.company_name = this.loggedUser.user_profile.company_name;
    this.cpf_cnpj = this.loggedUser.user_profile.cpf_cnpj;
    this.phone = this.loggedUser.user_profile.phone;
    this.zipcode = this.loggedUser.user_profile.zipcode;
    this.number = this.loggedUser.user_profile.number;
    this.complement = this.loggedUser.user_profile.complement;
    this.email_billing = this.loggedUser.user_profile.email_billing;

    if (!this.email_billing) {
      this.email_billing = this.loggedUser.email;
    }

    let iplan = this.plans.findIndex(
      ({ id }) => id == this.payload.plan_identity_number
    );
    this.reloadQuotaOptions(iplan);
    this.selectedPlanIndex = iplan;
    this.selectedCommitment = this.payload.is_yearly ? "yearly" : "monthly";
    this.selectedDataRange = this.payload.plan_data_range;
    this.setQuotaIndexByDataRange(this.payload.plan_data_range);
  }
};
</script>

<style scoped>
.selected-plan-info {
  font-size: 12pt;
}
.alert {
  margin-bottom: 0;
}
.btn.contract {
  font-size: 14pt;
  border-radius: 30px;
}
</style>
